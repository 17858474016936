import request from '@/utils/request'
// import { mockService } from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';

// 根据停车场名称和车场类型，分页查询车场列表
export function parkingLotPage(params) {
    return request.post('/monitor/parkingLotPage?' + objectToQueryString(params));
}
// 根据停车场获取泊位列表
export function getParkingSpaceByParkingLot(params) {
    return request.post('/spaceMonitor/findSpaceByParkingLot?' + objectToQueryString(params));
}

// 泊位监控-泊位统计
export function getParkingSpaceTotal(params) {
    return request.post('/monitor/getParkingSpaceTotal?' + objectToQueryString(params));
}

// 泊位监控-车场统计
export function getParkingLotTotal(params) {
    return request.post('/monitor/getParkingLotTotal?' + objectToQueryString(params));
}

// 获取泊位状态
export function searchParkingSpaceList(params) {
    return request.post('/monitor/searchParkingSpaceList?' + objectToQueryString(params));
}

// 获取当日泊位历史记录
export function getParkingSpaceNotes(params) {
    return request.post('/spaceMonitor/parkSpaceNotes?' + objectToQueryString(params));
}

//查询实时监控首页数据
export function realtime(params) {
    return request.post('/monitor/searchParkingLotMinitorList?' + objectToQueryString(params));
}
//查询实时监控-收入统计
export function searchParkingLotEarn(params) {
    return request.post('/monitor/searchParkingLotEarn?' + objectToQueryString(params));
}

//查询实时监控-收入统计
export function searchParkingSpaceTotal(params) {
    return request.post('/monitor/searchParkingSpaceTotal?' + objectToQueryString(params));
}

//查询实时监控-车型占比
export function searchParkingVehicleRate(params) {
    return request.post('/monitor/searchParkingVehicleRate?' + objectToQueryString(params));
}

//根据停车场ID查询车场信息
export function getDetailByParkingLotId(parkingLotId) {
    return request.post('/monitor/getDetailByParkingLotId?parkingLotId=' + parkingLotId);
}

//获取金额统计信息
export function getAmountRelated(parklotId, timeType) {
    return request.post('/monitor/getAmountRelated?parklotId=' + parklotId + '&timeType=' + timeType);
}

//车辆信息监控
export function vehicleMonitor(parklotId) {
    return request.post('/monitor/vehicleMonitor?parklotId=' + parklotId);
}

//出入口流量分析
export function trafficAnalysis(parklotId, entryOrExit) {
    return request.post('/monitor/trafficAnalysis?parklotId=' + parklotId + '&entryOrExit=' + entryOrExit);
}

//近24小时泊位占用率
export function spaceOccupancyRate(parklotId) {
    return request.post('/monitor/spaceOccupancyRate?parklotId=' + parklotId);
}

//设备监控
export function deviceMonitor(parklotId) {
    return request.post('/monitor/deviceMonitor?parklotId=' + parklotId);
}

//巡检监控
export function inspectorMonitor(parklotId) {
    return request.post('/monitor/inspectorMonitor?parklotId=' + parklotId);
}

// 泊位监控-根据车辆查停车时长-停车次数
export function searchParkingVehicleTotal(params) {
    return request.post('/monitor/searchParkingVehicleTotal?' + objectToQueryString(params));
}

// 泊位监控-车辆历史订单
export function searchParkingVehiclePage(params) {
    return request.post('/monitor/searchParkingVehiclePage?' + objectToQueryString(params));
}

// 泊位监控-根据泊位查历史欠费 历史缴费金额
export function searchParkingOrderBySpaceTotal(params) {
    return request.post('/monitor/searchParkingOrderBySpaceTotal?' + objectToQueryString(params));
}

// 泊位监控-泊位历史订单
export function searchParkingOrderBySpace(params) {
    return request.post('/monitor/searchParkingOrderBySpace?' + objectToQueryString(params));
}
