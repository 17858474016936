<template>
  <div v-loading="isLoading" class="realtime-monitor">
    <div class="headLine">
      <div style="display: flex;align-items: center;">
        <el-input
            v-model="query.parkingLotName"
            placeholder="请输入车场名称查询"
            prefix-icon="el-icon-search"
            class="width260 margin-right10 margin-left38"
        />
        <el-button type="primary" @click="doSearch">搜索</el-button>
        <el-button @click="resetForm">重置</el-button>
      </div>
      <div style="display: flex;align-items: center;">
        <el-checkbox-group v-model="query.parkingLotTypeArr">
          <el-checkbox label="1">路内</el-checkbox>
          <el-checkbox label="0">路外</el-checkbox>
        </el-checkbox-group>
      </div>

      <div style="display: flex;align-items: center;">
        <div style="display: flex;">
          <img src="@/assets/images/monitor/ccsl.png" class="width29 height29"/>
          <div class="margin-left12" style="display: flex;flex-direction: column;">
            <div class="font-size14" style="color: #5F666C;">路内车场</div>
            <div class="font-size20" style="font-weight: bold;">{{summaryData.parkingLotInnerTotalCount}}</div>
          </div>
        </div>
        <div class="margin-left30" style="display: flex;">
          <img src="@/assets/images/monitor/bwsl.png" class="width29 height29"/>
          <div class="margin-left12" style="display: flex;flex-direction: column;">
            <div class="font-size12" style="color: #5F666C;">路内泊位</div>
            <div class="font-size20" style="font-weight: bold;">{{summaryData.parkingLotInnerSpaceCount}}</div>
          </div>
        </div>
        <div class="margin-left30" style="display: flex;">
          <img src="@/assets/images/monitor/zybw.png" class="width29 height29"/>
          <div class="margin-left12" style="display: flex;flex-direction: column;">
            <div class="font-size14" style="color: #5F666C;">占用泊位</div>
            <div class="font-size20" style="font-weight: bold;">{{summaryData.parkingLotInnerSpaceUseCount}}</div>
          </div>
        </div>
      </div>

      <div class="margin-right38" style="display: flex;align-items: center;">
        <div style="display: flex;">
          <img src="@/assets/images/monitor/ccsl.png" class="width29 height29"/>
          <div class="margin-left12" style="display: flex;flex-direction: column;">
            <div class="font-size14" style="color: #5F666C;">路外车场</div>
            <div class="font-size20" style="font-weight: bold;">{{summaryData.parkingLotOutTotalCount}}</div>
          </div>
        </div>
        <div class="margin-left30" style="display: flex;">
          <img src="@/assets/images/monitor/bwsl.png" class="width29 height29"/>
          <div class="margin-left12" style="display: flex;flex-direction: column;">
            <div class="font-size14" style="color: #5F666C;">路外泊位</div>
            <div class="font-size20" style="font-weight: bold;">{{summaryData.parkingLotOutSpaceCount||'0'}}</div>
          </div>
        </div>
        <div class="margin-left30" style="display: flex;">
          <img src="@/assets/images/monitor/zybw.png" class="width29 height29"/>
          <div class="margin-left12" style="display: flex;flex-direction: column;">
            <div class="font-size14" style="color: #5F666C;">占用泊位</div>
            <div class="font-size20" style="font-weight: bold;">{{summaryData.parkingLotOutSpaceUseCount}}</div>
          </div>
        </div>
      </div>
    </div>
    <div id="map-container" class="map-container">
    </div>

    <!--车场面板-->
    <div v-if="curPoi!=null" id="road-outside" class="road-card" style="height: fit-content;">
      <div class="item-content">
        <div class="title">{{ curPoi.parkingLotName }}</div>
<!--        <div class="sub-title">山东省青岛市崂山区株洲路143号</div>-->
        <div class="margin-top10" style="display: flex;">
          <div class="tag1">{{curPoi.parkingLotType===1?'路内车场':'路外车场'}}</div>
<!--          <div class="tag2">车场收入排名：01</div>-->
        </div>
      </div>
      <el-collapse v-model="activedCollapseIndex">
        <el-collapse-item name="1" title="收入统计">
          <div class="item-content">
            <div style="display: flex;">
              <div class="sub-item width88">
                <div class="title">
                  今日应收
<!--                  <img src="@/assets/images/trend_up.png" style="width: 16px;height: 16px;margin-left: 5px;">-->
                </div>
                <div class="content">¥ {{parkinglotEarn.receivableAmount}}</div>
              </div>
              <div class="sub-item width88 margin-left50">
                <div class="title">
                  今日实收
<!--                  <div-->
<!--                      style="margin-left: 5px;width: 33px;height: 16px;background: #3075FA;color: white;display: flex;align-items:center;justify-content: center;font-size: 10px;border-radius: 30px 30px 30px 30px;opacity: 1;">-->
<!--                    1ST-->
<!--                  </div>-->
                </div>
                <div class="content">¥ {{ parkinglotEarn.transactionAmount }}</div>
              </div>
            </div>
            <div class="margin-top18" style="display: flex;">
              <div class="sub-item width88">
                <div class="title">
                  单泊位收益
<!--                  <img src="@/assets/images/trend_down.png" style="width: 16px;height: 16px;margin-left: 5px;">-->
                </div>
                <div class="content">¥ {{ parkinglotEarn.spaceEarn }}</div>
              </div>
              <div class="sub-item width88 margin-left50">
                <div class="title">
                  收缴率
<!--                  <img src="@/assets/images/trend_up.png" style="width: 16px;height: 16px;margin-left: 5px;">-->
                </div>
                <div class="content">{{parkinglotEarn.payRate}}%</div>
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item name="2" title="泊位统计">
          <div class="height180">
            <BaseChart
                ref="bwtjChart"
                :option="bwtjChartOption"
                class="width330 height180"/>
          </div>
        </el-collapse-item>
<!--        <el-collapse-item name="3" title="设备统计">-->
<!--          <div class="item-content">-->
<!--            <div style="display: grid;grid-template-columns: auto auto auto auto;">-->
<!--              <div style="display: flex;flex-wrap: wrap;align-items: center;">-->
<!--                <img src="@/assets/images/realtime/dc.png" style="width: 24px;height: 24px;"/>-->
<!--                <div style="margin-left: 2px;">-->
<!--                  <div style="font-size: 12px;line-height: 12px;color: #5F666C;">地磁</div>-->
<!--                  <div style="font-size: 16px;line-height: 16px;margin-top: 4px;font-weight: bold;">30</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div style="display: flex;align-items: center;">-->
<!--                <img src="@/assets/images/realtime/dz.png" style="width: 24px;height: 24px;"/>-->
<!--                <div style="margin-left: 2px;">-->
<!--                  <div style="font-size: 12px;line-height: 12px;color: #5F666C;">道闸</div>-->
<!--                  <div style="font-size: 16px;line-height: 16px;margin-top: 4px;font-weight: bold;">4</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div style="display: flex;align-items: center;">-->
<!--                <img src="@/assets/images/realtime/pda.png" style="width: 24px;height: 24px;"/>-->
<!--                <div style="margin-left: 2px;">-->
<!--                  <div style="font-size: 12px;line-height: 12px;color: #5F666C;">PDA</div>-->
<!--                  <div style="font-size: 16px;line-height: 16px;margin-top: 4px;font-weight: bold;">3</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div style="display: flex;align-items: center;">-->
<!--                <img src="@/assets/images/realtime/xjc.png" style="width: 24px;height: 24px;"/>-->
<!--                <div style="margin-left: 2px;">-->
<!--                  <div style="font-size: 12px;line-height: 12px;color: #5F666C;">巡检车</div>-->
<!--                  <div style="font-size: 16px;line-height: 16px;margin-top: 4px;font-weight: bold;">2</div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div style="display: grid;grid-template-columns: auto auto auto auto;margin-top: 18px;">-->
<!--              <div style="display: flex;align-items: center;">-->
<!--                <img src="@/assets/images/realtime/gd.png" style="width: 24px;height: 24px;"/>-->
<!--                <div style="margin-left: 2px;">-->
<!--                  <div style="font-size: 12px;line-height: 12px;color: #5F666C;">高点</div>-->
<!--                  <div style="font-size: 16px;line-height: 16px;margin-top: 4px;font-weight: bold;">12</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div style="display: flex;align-items: center;">-->
<!--                <img src="@/assets/images/realtime/ds.png" style="width: 24px;height: 24px;"/>-->
<!--                <div style="margin-left: 2px;">-->
<!--                  <div style="font-size: 12px;line-height: 12px;color: #5F666C;">地锁</div>-->
<!--                  <div style="font-size: 16px;line-height: 16px;margin-top: 4px;font-weight: bold;">45</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div style="display: flex;align-items: center;">-->
<!--                <img src="@/assets/images/realtime/spz.png" style="width: 24px;height: 24px;"/>-->
<!--                <div style="margin-left: 2px;">-->
<!--                  <div style="font-size: 12px;line-height: 12px;color: #5F666C;">视频桩</div>-->
<!--                  <div style="font-size: 16px;line-height: 16px;margin-top: 4px;font-weight: bold;">9</div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div style="display: flex;align-items: center;">-->
<!--                <div style="width: 24px;height: 24px;"></div>-->
<!--                <div style="margin-left: 2px;width: 36px;">-->
<!--                  <div style="font-size: 12px;line-height: 12px;color: #5F666C;"></div>-->
<!--                  <div style="font-size: 16px;line-height: 16px;margin-top: 4px;font-weight: bold;"></div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </el-collapse-item>-->
<!--        <el-collapse-item name="4" title="人员配置">-->
<!--          <div class="item-content">-->
<!--            <div class="sub-item">-->
<!--              <div class="title">巡检人员</div>-->
<!--              <div class="content">1</div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </el-collapse-item>-->
<!--        <el-collapse-item name="5" title="巡检任务">-->
<!--          <div class="item-content">-->
<!--            <div style="display: flex;">-->
<!--              <div class="sub-item">-->
<!--                <div class="title">下发任务</div>-->
<!--                <div class="content">1812</div>-->
<!--              </div>-->
<!--              <div class="sub-item" style="margin-left: 50px;">-->
<!--                <div class="title">已处理问题</div>-->
<!--                <div class="content">1660</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </el-collapse-item>-->
<!--        <el-collapse-item name="6" title="收费路口">-->
<!--          <div class="item-content">-->
<!--            <div style="display: flex;">-->
<!--              <div class="sub-item">-->
<!--                <div class="title">进口</div>-->
<!--                <div class="content">2</div>-->
<!--              </div>-->
<!--              <div class="sub-item" style="margin-left: 50px;">-->
<!--                <div class="title">出口</div>-->
<!--                <div class="content">2</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </el-collapse-item>-->
      </el-collapse>
    </div>
  </div>
</template>

<script>
import LoadMap from "@/common/LoadMap";
import insideIcon from "@/assets/images/monitor/road_inside_new.png";
import outsideIcon from "@/assets/images/monitor/road_outside.png";
// import {getParkingList} from "@/api/parking";
import {
  realtime,
  searchParkingLotEarn,
  searchParkingSpaceTotal,
  searchParkingVehicleRate,
} from "@/api/monitor";
import BaseChart from "@/components/BaseChart.vue";
import {
  checkToken,
} from "@/api/common";
import {MessageBox} from "element-ui";
// import {getParkingSpaceTotal} from "@/api/home";

export default {
  name: "realtime-monitor",
  components: {BaseChart},
  data() {
    return {
      activedCollapseIndex:['1', '2', '3', '4', '5', '6'],
      summaryData:{},
      curPoi:null,
      mapObjList:[],
      parkinglotEarn: {},
      bwtjChartOption: {
        // width:'100%',
        // height:'100%',
        grid: {
          // width:278, //设置宽度为800px
          // height:120,
          // containLabel: true,
          // left: 20,
          // right: 20,
          // bottom: 20,
          // top: 30,
        },
        color: ['#3075FA', '#0768FD', '#FFBB01', '#876DFF'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          bottom: '5%',
          left: 'center',
          icon: 'circle',
        },
        graphic: [
          {
            type: "text",
            left: "9%",
            top: "25%",
            style: {
              fill: "#5F666C",
              fontSize: 12,//字号
              text: "泊位总数",
            }
          },
          {
            type: "text",
            left: "9%",
            top: "35%",
            style: {
              fontSize: 16,//字号
              fontWeight: 600,
              text: "5,187",
            }
          },
          {
            type: "text",
            left: "33%",
            top: "25%",
            style: {
              fill: "#5F666C",
              fontSize: 12,//字号
              text: "周转率",
            }
          },
          {
            type: "text",
            left: "33%",
            top: "35%",
            style: {
              fontSize: 16,//字号
              fontWeight: 600,
              text: "4.2",
            }
          },
          {
            type: "text",
            left: "9%",
            top: "50%",
            style: {
              fill: "#5F666C",
              fontSize: 12,//字号
              text: "泊位占用",
            }
          },
          {
            type: "text",
            left: "9%",
            top: "60%",
            style: {
              fontSize: 16,//字号
              fontWeight: 600,
              text: "5,187",
            }
          },
          {
            type: "text",
            left: "33%",
            top: "50%",
            style: {
              fill: "#5F666C",
              fontSize: 12,//字号
              text: "泊位空闲",
            }
          },
          {
            type: "text",
            left: "33%",
            top: "60%",
            style: {
              fontSize: 16,//字号
              fontWeight: 600,
              text: "4.2",
            }
          },
        ],
        series: [
          {
            name: '泊位统计',
            type: 'pie',
            radius: ['35%', '55%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            center: ['70%', '40%'],
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              {value: 1048, name: '蓝牌'},
              {value: 735, name: '新能源'},
              {value: 580, name: '黄牌'},
              {value: 484, name: '其他'},
            ]
          }
        ]
      },
      isLoading: false,
      query: {
        // 停车场类型
        parkingLotTypeArr: ['0','1'],
        parkingLotType:'1',
        // 停车场状态
        parkingStatus: "",
        // 结算方ID
        settlementId: "",
        // 区划
        regionId: "530129",
        // 停车场名称
        parkingLotName: "",
        // 0-运营 1-机关事业单位 2-联网
        motionType: 0,
        pageNo: 1,
        pageSize: 9999,
      },
      realtimeInfo: {},
    };
  },
  created() {
    this.$refs.bwtjChart.onResize()
  },
  methods: {
    initMap() {
      const BMap = window.BMap;
      this.map = new BMap.Map("map-container", {enableMapClick: false});
      // 地图响应鼠标滚轮滑动
      this.map.enableScrollWheelZoom();
      var point = new BMap.Point(108.5525, 40.3227);
      this.map.centerAndZoom(point, 5);
      var that = this
      this.map.addEventListener("click", function () {
        that.curPoi = null
        that.resetMapLabelStatus()
      })
    },
    async searchParkingLotEarn(parkingLotId) {
      const res = await searchParkingLotEarn({parkingLotId: parkingLotId});
      if (res && res.code === 30 && res.result) {
        console.log(res.returnObject)
        this.parkinglotEarn = res.returnObject
      }
    },
    async searchParkingSpaceTotal(parkingLotId) {
      const res = await searchParkingSpaceTotal({parkingLotId: parkingLotId});
      if (res && res.code === 30 && res.result) {
        this.bwtjChartOption.graphic[1].style.text = res.returnObject.spaceTotalCount
        this.bwtjChartOption.graphic[3].style.text = res.returnObject.parkingRate
        this.bwtjChartOption.graphic[5].style.text = res.returnObject.spaceUseCount
        this.bwtjChartOption.graphic[7].style.text = res.returnObject.spaceEmptyCount
        this.$refs.bwtjChart.render(this.bwtjChartOption);
      }
    },
    async searchParkingVehicleRate(parkingLotId) {
      const res = await searchParkingVehicleRate({parkingLotId: parkingLotId});
      if (res && res.code === 30 && res.result) {
        this.bwtjChartOption.series[0].data[0].value = res.returnObject.blueCarRate
        this.bwtjChartOption.series[0].data[1].value = res.returnObject.greenCarRate
        this.bwtjChartOption.series[0].data[2].value = res.returnObject.yellowCarRate
        this.bwtjChartOption.series[0].data[3].value = res.returnObject.otherCarRate
        this.$refs.bwtjChart.render(this.bwtjChartOption);
      }
    },
    removeMarkers(){
      var allOverlay = this.map.getOverlays();
      for (var i = 0; i < allOverlay.length -1; i++){
        this.map.removeOverlay(allOverlay[i]);
      }
    },
    resetMapLabelStatus(){
      this.mapObjList.forEach(m => {
        m.label.setStyle({
          // display: 'none',
          // border: '0px',
          // backgroundColor: '000000000001' // 用于设置背景透明色
          color: "#fff",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          borderRadius: "10px",
          padding: "0 10px",
          fontSize: "14px",
          lineHeight: "20px",
          border :"0",
          transform:'translateX(-50%)'
        })
      })
    },
    //创建路内标记点
    initInsideMarker(item) {
      var _this = this
      const BMap = window.BMap;
      var point = new BMap.Point(item.longitude, item.latitude);
      var inside_icon = new BMap.Icon(insideIcon, new BMap.Size(56, 56));
      var marker = new BMap.Marker(point, {icon: inside_icon});
      this.map.addOverlay(marker);

      // 创建label文本标注
      const label = new BMap.Label(item.parkingLotName, {
        position: point, // 文本绑定的点位位置
        offset: new BMap.Size(0, -45) // 文本位置移动
      })

      // 设置文本标注样式（刚开始标注隐藏，鼠标移入的时候再显示，如果要全部显示就直接控制display属性即可）
      label.setStyle({
        // display: 'none',
        // border: '0px',
        // backgroundColor: '000000000001' // 用于设置背景透明色
        color: "#fff",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        borderRadius: "10px",
        padding: "0 10px",
        fontSize: "14px",
        lineHeight: "20px",
        border :"0",
        transform:'translateX(-50%)'
      })

      this.mapObjList.push({id:item.parkingLotId, marker:marker, label:label})
      marker.addEventListener("click", function (event) {
        console.log('event:', event)
        // 阻止事件冒泡
        event.domEvent.stopPropagation()
        _this.resetMapLabelStatus()
        label.setStyle({
          // display: 'none',
          // border: '0px',
          // backgroundColor: '000000000001' // 用于设置背景透明色
          color: "#ffffff",
          borderColor: "rgba(255, 255, 255, 0.5)",
          backgroundColor:"#0768FD",
          borderRadius: "10px",
          padding: "0 10px",
          fontSize: "14px",
          lineHeight: "20px",
          border :"0",
          transform:'translateX(-50%)'
        })

        _this.curPoi = item;
        console.log('bbbbbb')
        _this.searchParkingLotEarn(item.parkingLotId)
        _this.searchParkingSpaceTotal(item.parkingLotId)
        _this.searchParkingVehicleRate(item.parkingLotId)
        // alert('parkingId：',item.parkingLotId);  // 监听点击事件
        // window.location.href = `${window.location.protocol}//${window.location.host}/#/monitor/road_inside_monitor?parkingLotId=${parkingId}`;
      });

      // 通过鼠标移入marker的时候显示label标注
      // marker.addEventListener('mouseover', function (e) {
      //   console.log(e)
      //   label.setStyle({ display: 'block' })
      // })
      //
      // marker.addEventListener('mouseout', function (e) {
      //   console.log(e)
      //   label.setStyle({ display: 'none' })
      // })

      this.map.addOverlay(label);

      var options = {
        size: 4,
        shape: 1,
        color: '#d340c3'
      }
      var pointCollection = new BMap.PointCollection([point], options);
      // 初始化PointCollection
      // pointCollection.setStyles({
      //   width:56,
      //   height:56,
      //   icon: inside_icon,
      // })
      pointCollection.addEventListener('click', function (e) {
        alert('单击点的坐标为：' + JSON.stringify(e));  // 监听点击事件
      });
      // this.map.addOverlay(pointCollection);  // 添加Overlay
      // var parkingId = item.id;
    },
    //创建路外标记点
    initOutsideMarker(item) {
      var _this = this
      const BMap = window.BMap;
      var point = new BMap.Point(item.longitude, item.latitude);
      var outside_icon = new BMap.Icon(outsideIcon, new BMap.Size(56, 56));
      var marker = new BMap.Marker(point, {icon: outside_icon});
      this.map.addOverlay(marker);
      marker.addEventListener("click", function (event) {
        event.domEvent.stopPropagation()

        _this.curPoi = item;
        _this.searchParkingLotEarn(item.parkingLotId) 
        _this.searchParkingSpaceTotal(item.parkingLotId)
        _this.searchParkingVehicleRate(item.parkingLotId)
        // alert('parkingId：',item.parkingLotId);  // 监听点击事件
        // window.location.href = `${window.location.protocol}//${window.location.host}/#/monitor/road_inside_monitor?parkingLotId=${parkingId}`;
      });

      var options = {
        size: 4,
        shape: 1,
        color: '#d340c3'
      }
      var pointCollection = new BMap.PointCollection([point], options);
      // 初始化PointCollection
      // pointCollection.setStyles({
      //   width:56,
      //   height:56,
      //   icon: inside_icon,
      // })
      pointCollection.addEventListener('click', function (e) {
        alert('单击点的坐标为：' + JSON.stringify(e));  // 监听点击事件
      });
      // this.map.addOverlay(pointCollection);  // 添加Overlay
      // var parkingId = item.id;
    },
    //清除标记点
    removeOverlay() {
      let allOverlayList = this.map.getOverlays();
      //循坏所有点
      for (var i = 0; i < allOverlayList.length; i++) {
        this.map.removeOverlay(allOverlayList[i]);
      }
      // this.map.clearOverlays();
    },
    resetForm(){
      this.query = {
        // 停车场类型
        parkingLotTypeArr: ['0','1'],
        parkingLotType:'1',
        // 停车场状态
        parkingStatus: "",
        // 结算方ID
        settlementId: "",
        // 区划
        regionId: "530129",
        // 停车场名称
        parkingLotName: "",
        // 0-运营 1-机关事业单位 2-联网
        motionType: 0,
        pageNo: 1,
        pageSize: 9999,
      };
      this.doSearch();
    },
    //查询
    doSearch() {
      this.getParkinglotList();
    },
    getParkinglotList() {
      // this.isLoading = true;
      this.curPoi = null
      console.log('this.query:',this.query)
      if(this.query.parkingLotTypeArr.length===0){
        this.$message.error('请选择车场类型后重试!')
        return
      }
      else if(this.query.parkingLotTypeArr.length===2){
        this.query.parkingLotType='2'
      }else{
        this.query.parkingLotType = this.query.parkingLotTypeArr[0]
      }
      realtime(this.query).then((res) => {
        this.removeOverlay();
        this.removeMarkers()
        // this.isLoading = false;
        if (res && res.code === 30 && res.result) {
          this.summaryData = res.returnObject
          const list = res.returnObject.parkingLotMonitorVoList;
          if (list.length > 0) {
            list.map((item) => {
              if (item.parkingLotType === 1) {
                this.initInsideMarker(item);
              }
              if (item.parkingLotType === 0) {
                this.initOutsideMarker(item);
              }
            });
          }
        }
      });
    },
    // getRealtimeInfo() {
    //   // const regionId = "530129";
    //   realtime({parkingLotType:1}).then((res) => {
    //     if (res && res.code === 30 && res.result) {
    //       this.realtimeInfo = res.returnObject.parkingLotMonitorVoList;
    //     }
    //   });
    // },
  },
  async mounted() {
    checkToken()
        .then(async res => {
          console.log('checkToken', res)
          if (!res || res.code !== 30) {
            MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
              confirmButtonText: '重新登录',
              callback: () => {
                window.localStorage.clear()
                window.$vue.$router.push('/login')
              }
            });
          } else {
            await LoadMap("oW2UEhdth2tRbEE4FUpF9E5YVDCIPYih");
            this.initMap();
            this.isLoading = true;
            Promise.all([this.getParkinglotList()]).then(() => {
              this.isLoading = false;
            });
          }
        })

  },
};
</script>

<style lang="less" scoped>
.realtime-monitor {
  width: 100%;
  height: calc(100% - 46px);
  //display: flex;

  overflow: hidden;
  position: relative;

  .headTitle {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #071F38;
    letter-spacing: 1px;
    margin-bottom: 10px;

    img {
      width: 24px;
      margin-right: 20px;
    }
  }

  .headLine {
    //margin: 10px;
    //background: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    height: 90px;
    width: calc(100% - 40px);
    flex: 1;
    margin: 20px;

    position: absolute;
    z-index: 100;
    background: #fff;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);

    border-radius: 6px;

    //.query-input{
    //  background:url(../../assets/images/search.png) no-repeat 10px center;
    //  padding: 0px 50px; /*输入内容左右边距控制*/
    //  width: 534px;
    //  height: 66px;
    //  font-size: 23px;
    //  color: #dadada;
    //}

    .parking-type-item {
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK-Regular, FZLanTingHei-M-GBK;
      font-weight: 400;
      color: #80838F;
      font-size: 12px;
      font-family: FZLanTingHei-M-GBK-Regular, FZLanTingHei-M-GBK;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      width: 80px;
      height: 30px;
      background: #18BA92;
      border-radius: 2px 2px 2px 2px;
      opacity: 1;
      line-height: 30px;


    }
  }

  .map-container {
    height: calc(100% - 8px);
    //height: calc(100% - 70px);
    background: #fff;
    margin: 10px;
  }

  .road-card {
    width: 323px;
    height: 228px;

    .item-content {
      margin: 20px 26px;

      .title {
        font-size: 18px;
        font-weight: bold;
      }

      .sub-title {
        font-size: 13px;
        color: #5F666C;
        margin-top: 10px;
      }

      .tag1 {
        background: #0768FD;
        border-radius: 2px;
        font-size: 12px;
        color: white;
        width: 64px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .tag2 {
        background: #3075FA;
        border-radius: 2px;
        font-size: 12px;
        color: white;
        height: 22px;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        margin-left: 10px;
      }

      .sub-item {
        .title {
          font-size: 12px;
          color: #5F666C;
          display: flex;
        }

        .content {
          font-size: 16px;
          font-weight: bold;
          color: #000000;
        }
      }
    }


    position: absolute;
    z-index: 100;
    background: #fff;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);

    border-radius: 6px;

    .box {
      width: 130px;
      height: 70px;
      background: #F0F3F5;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      margin-top: 10px;
      //padding: 10px;
      .box-type {
        font-size: 12px;
        font-family: FZLanTingHeiS-R-GB-Regular, FZLanTingHeiS-R-GB;
        font-weight: 400;
        color: #707578;
        line-height: 14px;
      }

      .box-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 14px;
        font-family: FZLanTingHeiS-B-GB-Regular, FZLanTingHeiS-B-GB;
        font-weight: 400;
        color: #3A444A;
        line-height: 16px;
        margin-bottom: 12px;
        align-items: center;
        //margin-left: 10px;
        //margin-right: 10px;
        img {
          width: 25px;
          height: 25px;
        }
      }
    }

    .card-title {
      color: #0768FD;
      font-size: 14px;
      padding: 10px;
      text-align: center;
      position: relative;
      font-weight: 500;

      &::after {
        position: absolute;
        content: "";
        width: 27px;
        height: 2px;
        background: #0768FD;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .card-item {
      width: 183px;
      height: 66px;
      background: #0768FD;
      border-radius: 4px 4px 4px 4px;
      margin: 9px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon {
        width: 20px;
        height: 20px;
        margin: 0 10px;
      }

      .item-content {
        color: #fff;

        .label {
          font-size: 12px;
          margin-bottom: 8px;
        }

        .value {
          font-size: 17px;
        }
      }
    }
  }

  #road-outside {
    top: 130px;
    right: 20px;
  }

  #parking-type {
    position: absolute;
    bottom: 20px;
    left: 20px;

    z-index: 100;
    background: #fff;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);

    padding: 10px;
    padding-right: 0;

    border-radius: 6px;

    .parking-type-title {
      color: #80838f;
      font-size: 14px;
      padding: 0 0 10px 0;
    }

    .parking-type-content {
      display: flex;
      flex-direction: row;

      .parking-type-item {
        width: 93px;
        height: 28px;
        background: #18BA92;
        border-radius: 2px 2px 2px 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        margin-right: 10px;
      }
    }
  }
}

/deep/ .el-button--primary {
  color: white;
  background-color: #0768FD;
  border-color: #0768FD;
}

// 输入框样式
/deep/ .el-input__prefix, .el-input__suffix {
  color: #0768FD;
}

/deep/ .el-input__inner {
  background: #FAFAFA;
}

/deep/ .el-input--prefix .el-input__inner:focus {
  border-color: #0768FD;
}

/deep/ .el-collapse-item .el-collapse-item__header{
  padding: 0 26px;
  background: #F7F7F7;
  height: 30px;
  border: none !important;
}

/deep/ .el-collapse-item__content{
  padding-bottom: 0px;
}

// 多选框样式
/deep/ .el-checkbox__inner{
  border-radius: 50%;
}
/deep/ .el-checkbox__label{
  font-size: 16px;
  font-weight: 400;
  color: #596480;
  padding-left: 5px;
}
/deep/ .is-checked .el-checkbox__inner{
  border-radius: 50%;
  border: 1px solid #0768FD!important;
  background-color:transparent;
}
/deep/ .is-checked .el-checkbox__inner::after{
  width: 2px;
  height: 2px;
  content: "";
  border: none;
  background-color: #0768FD;
  left: 2.2px;
  top: 2px;
  border-radius: 50%;
  transform: none;
  //content: "";
  //position: absolute;
  //top: 50%;
  //left: 50%;
  //width: 26rpx;
  //height: 26rpx;
  //border: 6rpx solid #fff;
  //padding: 1px;
  //border-radius: 50%;
  //transform: translate(-50%,-50%);
}
</style>
